.leaderboard_bg {
    background-image: url(../../../public/AboutUsbanner.png) !important;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 0;
}

#cell-4{
    background-color: #ffffff;
}
.dODGfY, .kdoubu {
    background-color: black !important;

    background: linear-gradient(90deg, rgba(5, 213, 217, 0.37) 0%, rgba(248, 7, 252, 0.404) 100%);
}
.clubbought, .mHisf{
    background-color: black !important;

    background: linear-gradient(90deg, rgba(5, 213, 217, 0.37) 0%, rgba(248, 7, 252, 0.404) 100%);
}

.sc-koYCI , .sc-gjLLEI, .sc-gjLLEI{
    color: white !important;
    background: linear-gradient(90deg, rgba(5, 213, 217, 0.37) 0%, rgba(248, 7, 252, 0.404) 100%) !important;
    margin-right: 5px;
    border-radius: 10px !important;
    width: 30px !important ;
    height: 30px !important;
    display: flex !important;
    justify-content: center;
    align-items: center;
}


/* svg{
    fill:white;
    width: 12px;
    height: 12px;
} */