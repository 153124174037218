a.nav-link {
    font-weight: 300;
    padding: 0px !important;
    font-size: 17px;
    font-family: "Oswald", sans-serif;
}

.header_section {
    position: sticky;
    top: 0px;
    z-index: 2000;
    width: 100%;
    background: rgb(23 25 28 / 85%);
}

button.order-2.order-md-3.order-md-1.navbar-toggler {
    font-size: 12px;
}

.header .btn_uni {
    padding: 7px 29px;

}

button.order-2.order-md-1.order-md-1.navbar-toggler.collapsed {
    outline: none;
}

.navbar-brand img {
    padding: 5px 0px;
    height: 40px;
}

.me-auto.justify-content-center.navbar-nav {
    width: 100%;
}

.bg-body-tertiary {
    --bs-bg-opacity: 1;
    background-color: rgb(23 25 28 / 85%) !important;
}

a.btn_uni.nav-link {
    color: white;
}

.modal-content {
    background: rgba(18, 19, 20, 1);
    color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 6px 2px #484848;
    padding: 15px;
}

.modal h4 {
    color: #d5d5d5;
}

.modal .btn_uni {
    background: linear-gradient(to right, #1a2424, #2b1c2b) padding-box,
        linear-gradient(to right, #05D6D9, #F907FC) border-box;
    border-radius: 8px;
    font-weight: 400 !important;
    /* border: 1px solid transparent !important; */
    border: 1px solid #00000091 !important;
    font-size: 15px !important;
    text-decoration: none !important;
    font-family: "Oswald", sans-serif !important;
    color: white;
    padding: 10px 19px;
    text-transform: uppercase;
    transition: all .9s ease-out;
    transition: 0.9s;
}

.btn-close:focus {
    box-shadow: none !important;
}

.modal .btn-close {
    background-color: rgba(18, 19, 20, 1);
    --bs-btn-close-bg: url(../../../public/Icon.png);
}

.modal .modal-header {
    border-bottom: none !important;
    padding: 0px;
}

.modal-dialog {
    height: 100vh;
    display: flex;
    align-items: center;
}


/* =================dashboard header start================= */
/* .login_dtl>div img {
    height: 45px;
    width: auto;
}

.login_dtl h5 {
    font-size: 14px !important;
    margin: 0px;
}

.login_dtl p {
    margin: 0px;
} */

.login_dtl div img {
    /* border-radius: 50%;
    height: 45px;
    width: auto; */

    border-radius: 50%;
    height: 45px;
    width: 45px;
    object-fit: cover;
}

.header .dropdown-menu h5 {
    font-weight: 300;
}

.header {
    padding: 0px 30px;
}

.header .dropdown-menu .icon {
    font-size: 20px;
}

.header .dropdown-menu {
    padding: 20px 0px;
    background: rgba(18, 19, 20, 1);
    box-shadow: 0px 0px 2px 1px #777777;
}

.dropdown-menu[data-bs-popper] {
    top: 100%;
    right: 10px;
    margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu[data-bs-popper] {
    left: -125px;
}

/* =================dashboard header end================= */

.togglebtn {
    font-size: 25px;
}

.header .dropdown-toggle::after {
    right: -11px;
    top: 23px;
    position: absolute;
    margin-left: -0.745em;
    vertical-align: 0.255em;
    color: white;
}

.header .fw-normal {
    font-weight: 600 !important;
}

.header .text-info {
    font-size: 14px;
}

:focus-visible {
    outline: none !important;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

.active>a {
    color: rgba(11, 209, 217, 1) !important;
    font-weight: 400;
}

a.nav-link>a {
    padding: 3px 15px !important;
}

a.nav-link>a {
    padding: 3px 15px !important;
}

.w_add {
    padding-left: 6px;
    /* font-size: 13px; */
}

.h32 {
    height: 32px !important;
    width: auto;
}

@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
    .navbar-brand img {
        padding: 0px 0px;
        height: 16px;
    }

    .login_dtl>div img {
        height: 35px;
        width: 35px;
        object-fit: cover;
    }

    .header {
        padding: 0px 0px;
    }
}

@media screen and (min-device-width: 400px) and (max-device-width: 768px) {
    .header .btn_uni {
        padding: 3px 20px;
        font-size: 10px !important;
    }

    .header .w_add {
        font-size: 12px;
        padding-left: 3px;
    }
}

@media screen and (min-device-width: 200px) and (max-device-width: 400px) {

    .header .btn_uni {
        padding: 3px 5px;
        font-size: 10px !important;
    }

    .header .w_add {
        font-size: 8px;
        padding-left: 3px;
    }
}

@media screen and (min-device-width: 992px) and (max-device-width: 1200px) {
    .w_add {
        padding-left: 6px;
        font-size: 13px;
    }

    .header .btn_uni {
        padding: 7px 20px !important;
    }
}