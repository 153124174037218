.footer_banner {
    background-image: url(../../../public/banner/footerBanner.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 0;
}

.footer ul {
    list-style-type: none;
    color: rgba(255, 255, 255, 1);
    padding: 0px !important;
}

.footer .icons {
    justify-content: end;
}

.footer .icons li {
    font-size: 20px;
    margin: 0px 7px;
}

.footer ul li {
    font-family: "Montserrat", sans-serif;
    font-size: 14px;
    /* margin-right: 35px; */
}

.footer .logo_wrapper {
    margin-bottom: 50px;
}

.footer .row .col-md-6 a,
.footer .row .col-md-6 .h-100 {
    text-decoration: none;
    color: rgba(215, 215, 215, 1);
    font-weight: 200;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
}

.footer_banner .ftr_logo{
    aspect-ratio: 3/1;
    object-fit: contain;
}

@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
    .footer .logo_wrapper {
        margin-bottom: 30px;
    }

    .footer .icons {
        justify-content: center;
    }
}