body {
  background: #121314 !important;
}

.our_features,
.why_presale,
.how_it_works,
.our_packages,
.faq {
  padding: 3% 0px;
}

a {
  text-decoration: none !important;
  color: white !important;
}

h2 {
  font-size: 38px !important;
}

/* .main {
  background-image: url(../public/bg_img1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
} */

.mask {
  background: black;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  opacity: 0.2;
  z-index: -1;

}

/* :root {
  --blue: salmon;
  --white:  #05D6D9, #F907FC;
} */

.btn_uni {
  background: linear-gradient(to right, #1a2424, #2b1c2b) padding-box,
    linear-gradient(to right, #05D6D9, #F907FC) border-box;
  border-radius: 8px;
  font-weight: 400 !important;
  /* border: 1px solid transparent !important; */
  border: 1px solid #00000091 !important;
  font-size: 15px !important;
  text-decoration: none !important;
  font-family: "Oswald", sans-serif !important;
  color: white;
  padding: 10px 19px;
  text-transform: uppercase;
  transition: all .9s ease-out;
  transition: 0.9s;

  /* background: linear-gradient(to left, var(--blue) 50%, var(--white) 50%) right; */
  /* background-size: 200%; */
}

.btn_uni:hover {
  transition: all .9s ease-out;
  background: linear-gradient(to right, #05D6D9, #F907FC) border-box !important;
  /* background-position: left; */
}

.form-control {
  background: rgba(34, 38, 48, 1) !important;
  color: rgb(165, 165, 165) !important;
  outline: none !important;
  border: none !important;
  font-weight: 300;
  font-size: 12px;
  width: 100%;
  padding: 4px 11px;
  font-family: "Montserrat", sans-serif;
}

.form-control:focus {
  box-shadow: none !important;
}

input::placeholder {
  color: rgb(165, 165, 165) !important;
  font-weight: 300;
  font-size: 13px;
}

label {
  color: white;
  font-size: 13px;
  margin-bottom: 5px;
  font-family: "Montserrat", sans-serif;
}

input[type='radio']:after {
  width: 450px;
  height: 26px;
  border-radius: 15px;
  top: -7px;
  left: 0px;
  position: relative;
  background-color: transparent;
  content: '';
  display: block !important;
  visibility: visible;
  /* border: 1px solid white; */
}

input[type='radio']:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #6088e6;
  content: '';
  display: inline-block;
  visibility: visible;
  border: 1px solid white;
}

.redio_btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.redio_btn .r_icn1 {
  color: #f6851b;
  font-size: 16px;
}

.redio_btn .r_icn2 {
  color: #0051ff;
  font-size: 16px;
}

.redio_btn .r_icn3 {
  color: #3d8df9;
  font-size: 16px;
}

.redio_btn .r_icn4 {
  color: #3076bc;
  font-size: 21px;
}

/* ======================= animation and hover effect start ========================== */

.box,
.card_box {
  transition: 0.8s;
  transition: 0.5s;
}

.card_box:hover,
.box:hover {
  /* transform: rotateY(40deg); */
  box-shadow: 0px 0px 14px 8px #211d21;
}

.why_presale:hover .disc {
  transform: rotatex(360deg);
  transition: 0.8s;
}

.why_presale:hover .rocket {
  animation: MoveUpDown 2s linear infinite;
}

.footer a:hover {
  color: gray !important;
  transition: 0.3s;
}

.facebook_icon:hover {
  color: #4867aa !important;
}

.instagram_icon:hover {
  color: #c93c81 !important;
}

@keyframes MoveUpDown {

  0%,
  100% {
    top: 83px;
    left: 60px;
  }

  50% {
    top: 55px;
    left: 70px;
  }
}

/* ===============================animation and hover effect end================================ */


/* =================================== media queri ============================================= */

@media screen and (min-device-width: 200px) and (max-device-width: 768px) {

  nav.bg-body-tertiary.navbar.navbar-expand-lg.navbar-light.bg-dark {
    padding: 9px 1% !important;
  }

  .our_packages .card_box {
    padding: 20px 14px !important;
  }

  .dashboard_home .box {
    padding: 37px 20px !important;
  }

  .buy_now {
    padding: 15px !important;
  }

  .ref_child {
    border-right: none !important;
    border-bottom: 1px solid gray;
  }

  button.order-2.order-md-1.navbar-toggler.collapsed {
    font-size: 12px;
  }

  .first_slide {
    padding: 5% !important;
  }

  h2 {
    font-size: 25px !important;
  }

  h3 {
    font-size: 18px;
  }

  h5 {
    font-size: 18px;
  }

  p {
    font-size: 12px;
  }

  .btn_uni {
    font-size: 20px;
  }

  .main {
    background-image: url(../public/bg_img1.jpg);
    background-size: contain;
    background-repeat: initial;
  }

  .how_it_works,
  .our_packages {
    margin-top: 30px !important;
  }

  .dropdown span.ms-1 {
    font-size: 11px;
  }

  button#dropdownMenuButton1 {
    padding: 6px 3px;
  }

  .nfts .btn_uni {
    font-size: 10px !important;
  }

  .fltrnft input.form-control {
    padding: 6px 0px !important;
    padding-left: 35px !important;
  }

  .check_out_btn {
    justify-content: center;
    width: 25% !important;
  }

  .banner_content h1 {
    font-size: 20px !important;
  }

  .banner_content p {
    font-weight: 500 !important;
    font-size: 15px !important;
  }

  .claim_section {
    width: 100% !important;
  }

  .faq_banner,
  .our_packages_banner,
  .how_it_works_banner,
  .our_features_banner,
  .home_banner {
    background-size: contain !important;
  }
}

@media screen and (min-device-width: 768px) and (max-device-width: 904px) {
  .dropdown span.ms-1 {
    font-size: 11px;
  }

  .nfts .btn_uni {
    font-size: 10px !important;
  }
}


/* ============================ Nfts coming soon css ================== */

.nft_coming h1 {
  font-size: 72px;
  background: linear-gradient(to right, #05D6D9, #F907FC) border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card_box ul {
  font-size: 14px;
  color: rgba(215, 215, 215, 1) !important;
  font-weight: 300 !important;
  font-family: "Montserrat", sans-serif;
  padding-left: 25px;
}

.claim {
  /* background: linear-gradient(89.6deg, rgba(5, 214, 217, 0.2) 0.3%, rgba(249, 7, 252, 0.2) 99.65%); */
  /* background: linear-gradient(to right, #05D6D9, #F907FC) border-box !important; */
  /* border: 1px solid rgba(110, 110, 110, 0.377); */
  padding: 20px 30px;
  border-radius: 13px;

  /* width: 35%; */

  background: linear-gradient(89.6deg, rgba(5, 214, 217, 0.5) 0.3%, rgba(249, 7, 252, 0.5) 99.65%),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));


}

.claim button {
  background: linear-gradient(to right, #05D6D9, #F907FC) border-box !important;
  padding: 10px 20px;
}

.claim input {
  width: 100%;
  border-radius: 9px;
  padding: 10px;
  background: #cdcdcd !important;
  color: #1a2424 !important;
  /* border: navajowhite !important; */
  border: 1px solid #3c3c3c !important;
}

.claim input::placeholder {
  color: #1a2424 !important;
  font-weight: 400;
}

.home_pop .modal-content {
  /* background: linear-gradient(to right, #1a2424, #2b1c2b) padding-box,
    linear-gradient(to right, #05D6D9, #F907FC) border-box !important; */

  background-image: url(../public/popBG.jpg) !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

.modal .btn-close {
  z-index: 1 !important;
}

.main_pp .mask {
  background: black;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  bottom: 0px;
  opacity: 0.8;
  z-index: 0;
  left: 0px;
  border-radius: 10px;
}

.claim_section {
  background-image: url(../public/video/Maskgroup.png);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 16px;
}

/* .go4109123758{
  z-index: 1100000 !important;
} */

.bonus_nft_cards {
  font-size: 10px;
  text-align: center;
  /* padding: 0px 23px; */

}

.bonus_nft_cards span {
  height: 20px;
  display: flex;
  align-items: center;
  padding: 1px 17px;
  border-radius: 5px;
  background: linear-gradient(to right, #05d6d96b, #f907fc6b);

  position: absolute;
  bottom: 0px;
  right: 0px;
  border-radius: 30px 0px 0px 0px;
  /* text-shadow: 2px 2px #6c6c6c; */
}

.bonus_nft_cards span.badge {
  height: 20px;
  display: flex;
  align-items: center;
  padding: 1px 17px;
  border-radius: 5px;
  background: linear-gradient(to right, #05d6d96b, #f907fc6b);
  position: inherit;
  font-size: 10px;
  font-weight: 500;
  /* text-shadow: 2px 2px #6c6c6c; */
}

.activeNew {
  color: rgba(11, 209, 217, 1) !important;
}

/* .tooltip_child{
  position: absolute;
  top: 0px;
  display: none;
}

.tooltip:hover .tooltip_child{
  display: block;
}  */


/* =========================== custom_alert ========================== */

/* .custom_alert {
  background: white;
  border-radius: 10px;
  border: 1px solid yellow;
  box-shadow: 0px 0px 0px 0px gray;
  color: black;
  width: 250px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
} */

w3m-modal {
  padding-top: 5%;
}