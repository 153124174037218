.home_banner {
    /* position: relative; */
    background-image: url(../../../public/banner/bannerbackground/banner1.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    background-position: center;
    z-index: 0;
}

.banner_content {
    padding: 3% 0px;
}

.banner_mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: #121314;
    z-index: -1;
    opacity: 0.8;
}

.banner_content h1 {
    font-size: 45px;
    /* background: linear-gradient(to right, #05D6D9, #F907FC, #F907FC) border-box; */
    background: linear-gradient(to right, #F907FC, #F907FC, #6964ee, #6964ee, #5081e9, #05D6D9, #05D6D9) border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700;
}

.banner_content p {
    font-weight: 500;
    font-size: 22px;
}

.banner_btn {
    font-family: "Oswald", sans-serif !important;
    background: linear-gradient(to right, #05D6D9, #F907FC) border-box !important;
    color: white;
    border-radius: 0px;
    padding: 8px 35px;
}

/* .home_banner .content{
    display: flex;
    align-items: center;
} */

.triangle-down {
    /* transform: rotate(90deg);
    border-left: 0 solid #0000;
    border-right: 24px solid #0000;
    border-top: 24px solid #171f09;
    height: 0;
    top: -1px;
    position: absolute;
    width: 0;
    right: -1px; */
}