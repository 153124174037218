.our_features_banner {
    background-image: url(../../../public/banner/bannerbackground/banner2.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    background-position: center;
    z-index: 0;
}

.our_features .box {
    background: linear-gradient(28deg, #560f5a 0.3%, #360b38, #000000, #000000, #000000, #000000, #000000 99.65%);
    color: white;
    border-radius: 10px;
    box-shadow: 0px 0px 2px 1px #400d42;
    padding: 20px 15px;
    /* transition: transform 0.8s; */
    transition: 0.5s;
}


.our_features .box img {
    border-radius: 14px;
}

.our_features .box h4 {
    margin: 8px 0px;
    font-size: 18px;
}

.our_features .box p {
    font-weight: 300;
    font-size: 12px;
    margin: 0px !important;
}

.our_features .img-fluid {
    object-fit: cover;
    width: 100%;
    transition: all 0.9s;
}

/* .our_features .box:hover .img-fluid {
    object-fit: none;
    width: 100%;
} */