.my_referral .ref {
    /* display: flex;
    position: relative;
    align-items: center; */
    /* justify-content: space-around; */
    background: linear-gradient(89.6deg, rgba(5, 214, 217, 0.3) 0.3%, rgba(249, 7, 252, 0.3) 99.65%);
    color: white;
    padding: 10px 10px;
    border-radius: 5px;
    font-family: "Montserrat", sans-serif;
}

.ref_child {
    border-right: 1px solid gray;
}

.gFhLaO {
    background-color: #17191c !important;
}

.my_referral {
    background: rgba(23, 25, 28, 1);
    padding: 35px 10px;
    border-radius: 15px;
}

/* .my_referral .ref::after {
    content: "";
    background: gray;
    height: 30px;
    width: 1px;
    position: absolute;
    right: 50%;
} */

.my_referral .icon {
    position: absolute !important;
    top: 32px;
    right: 15px;
    color: rgb(134, 134, 134);
}

.my_referral .btn_uni {
    padding: 7px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.my_referral .btn_uni span {
    margin-left: 4px;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center
}

.my_referral img {
    height: 170px;
    width: auto;
}

.my_referral .ref_p {
    font-size: 12px;
}

.my_referral .address_btn {
    top: -10px;
    right: 5px;
    color: white;
    padding: 2px 10px;
    background: linear-gradient(89.6deg, rgba(5, 214, 217, 0.3) 0.3%, rgba(249, 7, 252, 0.3) 99.65%);
    border-radius: 5px;
    border: 1px solid rgb(116, 116, 116);
}

@media screen and (min-device-width: 344px) and (max-device-width: 500px) {

    .nav-tabs .active,
    .nav-tabs .nav-item button {
        padding: 3px 10px !important;
        /* font-size: 10px !important; */
    }
}