@font-face {
  font-family: Montserrat;
  src: url(../src/font/Montserrat/Montserrat-VariableFont_wght.ttf);
}

@font-face {
  font-family: Oswald;
  src: url(../src/font/Oswald/Oswald-VariableFont_wght.ttf);
}

body {
  --bs-body-bg: #000;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: white !important;
  font-family: "Oswald", sans-serif;
}

p {
  color: rgba(215, 215, 215, 1) !important;
  font-weight: 300 !important;
  font-family: "Montserrat", sans-serif;
}

.hd {
  text-align: center;
  margin-bottom: 10px;
}