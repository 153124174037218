.slick-next,
.slick-prev {
    right: 50% !important;
    bottom: -10%;
    display: none !important;
}

.slick-dots li.slick-active button:before {
    opacity: .75;
    color: #955be1 !important;
    font-size: 15px !important;
}

.slick-dots li button:before {
    color: white !important;
    font-size: 15px !important;
}

.slick-dots li button {
    color: white !important;
}

.first_slide {
    /* padding: 5% 25%; */
    padding-bottom: 0px;
}

.box {
    text-align: -webkit-center;
}

.slider_wrapper .box {
    text-align: center;
}

/* .col-md-4 .box .img-fluid {
    width: auto;
    height: 300px;
} */

.slider_wrapper h2 {
    font-size: 33px;
    margin: 15px 0px;
}

.slider_wrapper p {
    color: gray;
    font-size: 18px;
    font-weight: 400;
}

/* =================== 2 slide ========================== */


.second_slide p:nth-child(3) {
    font-family: "Oswald", sans-serif;
    margin-bottom: 3px;
}

.first_slide h1 {
    font-size: 50px !important;
    text-transform: uppercase;
}

.second_slide h2 {
    text-transform: uppercase;

}

/* =================== third slide ========================= */

.first_slide {
    padding: 5% 13%;
}

/* .second_slide,
.third_slide {
    padding: 5% 6%;
    padding-top: 5%;
} */

/* ================= media query ======================= */


@media screen and (min-device-width: 200px) and (max-device-width: 800px) {

    .content h2,
    .content2 h2 {
        font-size: 10px !important;
        margin-bottom: 0px;
    }

    .first_slide h1 {
        font-size: 16px !important;
    }

    .scnd_sliderBnr .btn_uni {
        font-size: 8px !important;
        padding: 3px 30px !important;
        margin-bottom: 0px !important;
    }

    .first_slide .btn_uni {
        font-size: 10px !important;
        padding: 6px 7px;
    }

    .second_slide p {
        font-size: 8px;
        margin-bottom: 0px !important;
    }

    .content,
    .content2 {
        top: 35px !important;
        right: 5px !important;
        width: 95% !important;
        padding: 5px 15px;
    }

    .slider,
    .second_slide,
    .third_slide {
        padding: 0%;
    }
}

@media screen and (min-device-width: 200px) and (max-device-width: 300px) {

    .content,
    .content2 {
        top: 12px !important;
    }
}

@media screen and (min-device-width: 800px) and (max-device-width: 1200px) {
    .first_slide {
        padding: 5% 10%;
        padding-bottom: 0px;
    }

    .second_slide h2 {
        font-size: 30px !important;
    }

    .second_slide p {
        font-size: 20px;
        margin-bottom: 5px !important;
    }

    .third_slide h2 {
        font-size: 35px !important;
    }

    .first_slide h2 {
        font-size: 20px !important;
    }
}

@media screen and (min-device-width: 1200px) and (max-device-width: 2800px) {

    .content {
        width: 60% !important;
    }
}

.scnd_sliderBnr img {
    width: 100%;

}

.scnd_sliderBnr {
    position: relative;
}

.content {
    text-align: end;
    position: absolute;
    top: 70px;
    right: 50px;
    width: 78%;
}

.slick-track {
    display: flex !important;
    align-items: center;
}

.content2 {
    text-align: end;
    position: absolute;
    top: 95px;
    width: 100%;
}

.third_slide .content2 {
    top: 102px;
}

.third_slide .mask {
    opacity: 0.3;
    z-index: 0;
    border-radius: 8px;
}