.pl-0 {
    padding-left: 0px;
}

.buy_now {
    background: #17191c;
    color: white;
    padding: 35px;
    border: 1px solid #282727;
    border-radius: 23px;
}

.buy_now .icon {
    padding-right: 4px;
}


.bn .description {
    box-shadow: 0px 0px 1px 1px #454545;
    /* margin: 15px 0px;
    padding: 25px;
    background: url(/public/nftbanner.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 14px; */
}

.up>.row {
    /* box-shadow: 0px 0px 1px 1px #454545; */
    margin: 15px 0px;
    padding: 25px;
    background: url(/public/nftbanner.png);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 14px;
}

.flagIcon {
    object-fit: contain;
}

.buy_now h5 {
    font-size: medium;
    font-weight: 600;
    /* margin-top: 11px; */
    margin-bottom: 2px;
    font-family: "Oswald", sans-serif;
}

.buy_now select {
    color: white;
    text-align: center;
}

.buy_now .slct_icon img {
    height: 18px;
}

.buy_now .slct_icon {
    position: absolute;
    z-index: 1;
    top: 4px;
    left: 15%;
}

.buy_now .drp_down {
    position: absolute;
    z-index: 1;
    top: 4px;
    right: 15%;
}

.mnt_usdt {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 19px !important;
}

.heading_packages {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(34, 38, 48, 1);
    padding: 10px 15px;
    border-radius: 8px;
}

.btn_packages {
    margin-top: 15px;
    background: linear-gradient(89.6deg, rgba(5, 214, 217, 0.2) 0.3%, rgba(249, 7, 252, 0.2) 99.65%);
    border-radius: 10px;
    padding: 1px;
}

.btn_packages>div {
    background: rgba(23, 25, 28, 1);
    padding: 8px 12px;
    border: 2px solid transparent;
    border-radius: 10px;
}

.buy_now .btn_uni {
    padding: 10px 0px;
}

@media (min-width: 576px) {
    .modal-dialog {
        /* max-width: 50% !important; */
        margin-right: auto;
        margin-left: auto;
    }
}