.how_it_works_banner {
    background-image: url(../../../public/banner/bannerbackground/banner6.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    background-position: center;
    z-index: 0;
}

.how_it_works .btn_hiw a {
    text-decoration: none !important;
    color: rgba(255, 255, 255, 1);
}

.how_it_works .btn_hiw {
    border-bottom: 1px solid #727272;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.how_it_works .btn_hiw .icon {
    color: white;
}

.how_it_works h3 {
    /* font-size: 18px; */
    font-size: 25px;
}

.how_it_works .choose_game p {
    /* font-size: 13px; */
    font-size: 15px;
}

.how_it_works .v_box {
    border: 1px solid white;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.how_it_works .v_box iframe {
    border-radius: 20px;
    max-width: 100%;
}

.how_it_works .v_box .ply_btn {
    color: #f34e4e;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    border-radius: 50%;
}

.how_it_works .col-md-3 {
    display: flex;
    align-items: center;
}

@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
    .how_it_works p {
        margin-bottom: 0px;
    }
}