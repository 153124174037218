.why_presale_banner {
    background-image: url(../../../public/banner/bannerbackground/banner3.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    position: relative;
    background-position: center;
    z-index: 0;
}

.why_presale {
    position: relative;
}

.why_presale .bg_wrapper {
    background: #17191c;
    border: 1px solid rgb(54, 54, 54);
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 20px;
    position: relative;
    padding: 45px 20px;
    z-index: 0;
}

.why_presale .wrapper {
    padding: 20px;
}

.why_presale .mask {
    opacity: 0.3;
    left: 0px;
    border-radius: 20px;
}

.why_presale b {
    font-weight: 600;
}

.why_presale .heading {
    background: #00000029;
    border: 1px solid rgba(110, 110, 110, 0.377);
    border-radius: 10px;
    padding: 15px 9px;
    font-size: 14px;
    margin-bottom: 5px;
}

.why_presale .heading_b {
    /* background: linear-gradient(89.6deg, rgba(5, 214, 217, 0.2) 0.3%, rgba(249, 7, 252, 0.2) 99.65%);
    border: 1px solid rgba(110, 110, 110, 0.377);*/
    background: linear-gradient(89.6deg, rgb(5 214 217 / 40%) 0.3%, rgb(249 7 252 / 44%) 99.65%);
    border: 1px solid rgb(110 110 110 / 79%);
    border-radius: 10px;
    padding: 5px 16px;
    font-size: 17px;
    font-weight: 600 !important;
    margin-bottom: 2px;
}

img.disc {
    height: 40%;
    max-width: 100%;
}

.rocket {
    width: auto;
    height: 15%;
    position: absolute;
    top: 90px;
    left: 60px;
    z-index: 1;
}

@media screen and (min-device-width: 200px) and (max-device-width: 768px) {
    .why_presale .rocket {
        display: none;
    }

    .why_presale .bg_wrapper{
        padding: 45px 0px;
    }
}


@media screen and (min-device-width: 768px) and (max-device-width: 1000px) {
    .why_presale .rocket {
        height: 9%;
    }
}