.poster-contain {
    position: absolute;
    width: 100%;
    top: 37%;
    left: 0%;
}

.poster-contain {
    padding: 0px 10px;
}

.ExploreOurFeatures_bg {
    background-image: url(../../../public/Features.png) !important;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 0;
}

.ExploreOurFeatures_childs p {
    font-size: 13px;
}

.ExploreOurFeatures_childs div img {
    width: 90%;
    height: auto;
}

.ExploreOurFeatures_childs .sub_heading {
    font-weight: 500 !important;
    font-size: 18px;
    color: white;
}

@media screen and (min-device-width: 200px) and (max-device-width: 850px) {
    .ExploreOurFeatures_childs div img {
        width: 100%;
        height: auto;
    }

    .poster-contain {
        
    }
}