.wallet_box{
    background-image: url(../../../public/bgcard.png);
    padding: 35px 25px;    
    background-repeat: no-repeat;
    background-size: cover;
    border: 1px solid #2e2e2e;
    border-radius: 10px;
    height: 100%;
}

@media screen and (min-device-width: 200px) and (max-device-width: 768px) { 
    .wallet_section .nav-tabs .nav-item button {
        padding: 8px 15px;
        width: 100%;
    }

    .wallet_section li.nav-item {
        width: 100%;
    }

    .wallet_box{
        padding: 15px;
    }

    .wallet_box h4{
        font-size: 16px;
    }

    .wallet_box .btn_uni {
        font-size: 13px !important;
        padding: 3px 14px;
    }
}